var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","fullscreen":"","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" Jugadores con credencial ")]),_c('v-spacer'),(_vm.excel && _vm.excel.length > 0 && _vm.excel[0].data.length > 0)?_c('xlsx-workbook',[_vm._l((_vm.excel),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":sheet.data,"sheet-name":sheet.name}})}),_c('xlsx-download',{attrs:{"filename":_vm.excelCredencialesName}},[_c('v-btn',{attrs:{"text":"","disabled":_vm.excel.length == 0}},[_vm._v(" Exportar a Excel ")])],1)],2):_vm._e(),(_vm.excel && _vm.excel.length > 0 && _vm.excel[0].data.length > 0)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.urlDownloadPhotos}},[_vm._v(" Descargar Fotos ")]):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('app.buttons.search'),"rounded":"","outlined":"","dense":"","append-icon":"mdi-magnify","clearable":""},model:{value:(_vm.filters.selected.query),callback:function ($$v) {_vm.$set(_vm.filters.selected, "query", $$v)},expression:"filters.selected.query"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"search":_vm.queryClean,"show-select":"","custom-filter":_vm.customFilter},scopedSlots:_vm._u([{key:"item.identificacion_verificada",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.identificacion_verificada ? _vm.$t('app.general.yes') : _vm.$t('app.general.no'))+" ")]}},{key:"item.credencial_activa",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","text-color":"white"}},[_vm._v(" "+_vm._s(item.credencial_activa ? _vm.$t('app.general.yes') : _vm.$t('app.general.no'))+" ")])]}},{key:"item.semaforo_credencial",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"justify-center"},[_c('v-chip',{attrs:{"text-color":item.semaforo_credencial.text,"color":item.semaforo_credencial.bg,"small":""}},[(item.credencial_activa)?[_vm._v(" "+_vm._s(_vm._f("moment")(item.fecha_pago_credencial,'add', '1 year', 'MMMM Do YYYY'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('app.general.no_credential'))+" ")]],2)],1)]}},{key:"item.equipo",fn:function(ref){
var item = ref.item;
return [_vm._l((item.equipo),function(x,index){return [_c('v-chip',{key:("team-" + index),attrs:{"label":""}},[_vm._v(" "+_vm._s(x.nombre)+" ")])]})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-flex justify-end"})]}}]),model:{value:(_vm.itemsSelected),callback:function ($$v) {_vm.itemsSelected=$$v},expression:"itemsSelected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }