<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    fullscreen
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Jugadores con credencial
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <xlsx-workbook v-if="excel && excel.length > 0 && excel[0].data.length > 0">
            <xlsx-sheet
              v-for="sheet in excel"
              :key="sheet.name"
              :collection="sheet.data"
              :sheet-name="sheet.name"
            />
            <xlsx-download
              :filename="excelCredencialesName"
            >
              <v-btn
                text
                :disabled="excel.length == 0"
              >
                Exportar a Excel
              </v-btn>
            </xlsx-download>
          </xlsx-workbook>
          <v-btn
            v-if="excel && excel.length > 0 && excel[0].data.length > 0"
            text
            @click="urlDownloadPhotos"
          >
            Descargar Fotos
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="filters.selected.query"
              :label="$t('app.buttons.search')"
              rounded
              outlined
              dense
              append-icon="mdi-magnify"
              clearable
            />
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="10"
          :search="queryClean"
          show-select
          v-model="itemsSelected"
          :custom-filter="customFilter"
        >
          <template v-slot:item.identificacion_verificada="{ item }">
            {{ item.identificacion_verificada ? $t('app.general.yes') : $t('app.general.no') }}
          </template>
          <template v-slot:item.credencial_activa="{ item }">
            <v-chip
              label
              text-color="white"
            >
              {{ item.credencial_activa ? $t('app.general.yes') : $t('app.general.no') }}
            </v-chip>
          </template>
          <template v-slot:item.semaforo_credencial="{ item }">
            <v-col class="justify-center">
              <v-chip
                :text-color="item.semaforo_credencial.text"
                :color="item.semaforo_credencial.bg"
                small
              >
                <template v-if="item.credencial_activa">
                  {{ item.fecha_pago_credencial | moment('add', '1 year', 'MMMM Do YYYY') }}
                </template>
                <template v-else>
                  {{ $t('app.general.no_credential') }}
                </template>
              </v-chip>
            </v-col>
          </template>
          <template v-slot:item.equipo="{ item }">
            <template v-for="(x, index) in item.equipo">
              <v-chip
                :key="`team-${index}`"
                label
              >
                {{ x.nombre }}
              </v-chip>
            </template>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-col class="d-flex justify-end">

            </v-col>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx'
import latinize from 'latinize'
import customFilter from "../../../../plugins/customFilter";

export default {
  name: "JugadoresImprimirCredenciales",
  mixins: [customFilter],
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data () {
    return {
      items: [],
      headers: [
        {
          text: this.$t('app.headers.id'),
          value: 'id',
        },
        {
          text: this.$t('app.headers.code'),
          value: 'alphacode',
        },
        {
          text: this.$t('app.headers.name'),
          value: 'persona.fullname',
        },
        {
          text: this.$t('app.equipos.name'),
          value: 'equipo',
        },
        {
          text: this.$t('app.headers.responsible_name'),
          value: 'nombre_persona_responsable',
        },
        {
          text: this.$t('app.headers.weight'),
          value: 'peso',
        },
        {
          text: this.$t('app.headers.height'),
          value: 'estatura',
        },
        {
          text: this.$t('app.headers.id_type'),
          value: 'tipo_identificacion.nombre',
        },
        {
          text: this.$t('app.headers.id_number'),
          value: 'numero_identificacion',
        },
        {
          text: this.$t('app.headers.id_verified'),
          value: 'identificacion_verificada',
        },
        {
          text: this.$t('app.headers.has_credential'),
          value: 'credencial_activa',
        },
        {
          text: this.$t('app.headers.credential_expire'),
          value: 'semaforo_credencial',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
      dialog: false,
      filters: {
        selected: {
          query: '',
        },
      },
      itemsSelected: [],
      excelCredencialesName: this.$t('app.jugadores.title') + ' para Credenciales - ' + this.$moment().format('YYYYMMDDHHmmss') + '.xlsx',
      excelHiddenColumns: [
        'id',
        'alphacode',
      ],
      excelForCredentials: [],
    }
  },
  async mounted() {
    EventBus.$on('jugadores-print-credentials', async () => {
      this.toggleLoader()

      await this.$http.get(route('v1/player/with_credential'))
        .then(response => {
          if (response.body.code === 200) {
            this.items = response.body.data
          } else {
            this.processError(response.body)
          }
        }, error => this.processError(error))

      this.toggleLoader()
      this.dialog = true
    })
  },
  computed: {
    excel () {
      return [{
        name: this.$t('app.general.report'),
        data: this.itemsSelected.map(item => {
          return {
            'ID Number': item.alphacode.replace('NF', ''),
            'First Name': item.persona.nombre,
            'Last Name': item.persona.apellido,
            'Expiration Date': this.$moment(item.fecha_pago_credencial).add(1, 'y').format('L'),
            Photo: item.persona.foto ? item.persona.foto.replace('fotos/', '') : '',
          }
        })
      }]
    },
    queryClean () {
      return latinize(this.filters.selected.query)
    }
  },
  methods: {
    async urlDownloadPhotos () {
      let params = {
        ids: this.itemsSelected.map(item => item.id)
      }

      await this.$http.post(route('v1/player/photos'), params)
        .then(response => {
          if (response.body.code === 200) {
            window.open(response.body.data.url)
          } else {
            this.processError(response.body)
          }
        }, error => this.processError(error))
    }
  }
}
</script>

<style scoped>

</style>
